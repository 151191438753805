import './_resume.scss';

function Resume () {
  return (
    <div className="resume">
      <header>
        <h1>Josh Mermelstein</h1>
        <h3>Software Engineer</h3>
        <address>
          Joshmerm@gmail.com | github.com/Joshmerm
        </address>
      </header>

      <section className="objective">
        <h2>Career Objective</h2>
        <p>
          As a software engineer, my objective is to enhance my technical skills and become a skilled software engineer while taking on leadership roles. I aspire to work in a challenging and dynamic environment where I can constantly learn, innovate, and contribute to the growth and success of the product.
        </p>
      </section>

      <section className="skills">
        <h2>Skills</h2>
        <ul className='skills__list'>
          <li>React JS</li>
          <li>Laravel</li>
          <li>Agile</li>
          <li>Angular JS</li>
          <li>Node JS</li>
          <li>Knex JS</li>
          <li>C</li>
          <li>C++</li>
          <li>C#</li>
          <li>.NET</li>
          <li>Java</li>
          <li>PostgreSQL</li>
          <li>Express JS</li>
          <li>SQL</li>
          <li>JQuery</li>
          <li>Haskell</li>
          <li>APIs</li>
          <li>Firebase</li>
          <li>Jira</li>
        </ul>
      </section>

      <section className="experience">
        <h2>Experience</h2>

        <div className="job">
          <h3>Latchel</h3>
          <p>Software Engineer II | November 2021 - Present</p>
          <ul>
            <li>Revamped frontend architecture by migrating Angular JS to React JS, boosting application performance, maintainability, and improving developer productivity.</li>
            <li>Led end-to-end architecture design and deployment of new features, resulting in faster on-boarding time, API load time, and higher user engagement.</li>
            <li>Implemented TDD PHP unit testing to enhance quality and stability.</li>
            <li>Created comprehensive technical documentation for custom components and libraries improving the development cycle.</li>
          </ul>
        </div>

        <div className="job">
          <h3>Generation Marketing</h3>
          <p>Software Engineer | May 2021 - September 2021</p>
          <ul>
            <li>Designed and developed a React JS application from scratch to create a map listing application.</li>
            <li>Developed Firebase and Geohashing solutions to optimize mapping efficiency.</li>
            <li>Designed and implemented a deployment pipeline, ensuring smooth and efficient deployment of code updates and releases.</li>
          </ul>
        </div>

        <div className="job">
          <h3>Fuloop</h3>
          <p>Full Stack Engineer Intern | May 2020 - August 2020</p>
          <ul>
            <li>Developed a React Native application, integrated third-party authentication services, designed backend schema and routes, and implemented AWS S3 file storage buckets for images.</li>
          </ul>
        </div>
        </section>

          <section className="education">
    <h2>Education</h2>
    <p>
      California State University - Los Angeles<br />
      Computer Science, Bachelor of Science<br />
      Magna Cum Laude - Deans List
    </p>
  </section>
</div>
)
}

export default Resume;


