import { FaLinkedinIn, FaGithub, FaCloudDownloadAlt, FaMailBulk } from 'react-icons/fa';
import './_home.scss';
import profileImage from 'images/profile.jpg';

function Home() {
  return (
    <div className='home-page'>
        <div className='home-page__intro'>
          Hello there! <br/>
          Im Josh Mermelstein.<br/>
          <span className='home-page__intro-job-title'>Im a software engineer.</span>
          <div className='home-page__intro-icons'>
            <a href='https://www.linkedin.com/in/josh-mermelstein-aa2404130' target="_blank" rel="noreferrer" className='icon clickable'>
              <FaLinkedinIn/>
            </a>
            <a href='https://github.com/Joshmerm' target="_blank" rel="noreferrer" className='icon clickable'>
              <FaGithub/>
            </a>
            <a href="mailto:joshmerm@gmail.com" target="_blank" rel="noreferrer" className='icon clickable'>
              <FaMailBulk/> 
            </a>
            <span className='icon clickable'>
              <FaCloudDownloadAlt onClick={() => {window.open('./Resume.pdf', "_blank")}}/>
            </span>
          </div>
        </div>
        <div className='home-page__image'>
          <img src={profileImage} alt='profile' />
        </div>
    </div>
  );
}

export default Home;
