import React from 'react';
import './_portfolio.scss';

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h2>Projects</h2>
      <div className="project">
        <h3>Helix</h3>
        <p className="project-duration">Aug 2021 - May 2022</p>
        <p className="project-description">
          C# .NET Web application. Team of 10. Lead FE architect. Supported BE in new features. Owner of FE squad.
        </p>
      </div>
      <div className="project">
        <h3>RuUp</h3>
        <p className="project-duration">May 2021 - August 2021</p>
        <p className="project-description">
          React Native application connecting friends, designed and built a communication platform.
        </p>
      </div>
    </div>
  );
};

export default Portfolio;
